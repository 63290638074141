var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_vm._m(0),_c('div',{staticClass:"bg-white w-full p-6 rounded-lg shadow"},[_c('form',{staticClass:"w-full",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"flex flex-wrap -mx-3 mb-6"},[_c('div',{staticClass:"w-full md:w-1/2 px-3 mb-6 md:mb-0"},[_c('label',{staticClass:"\n              block\n              uppercase\n              tracking-wide\n              text-gray-700 text-xs\n              font-bold\n              mb-2\n            ",attrs:{"for":"grid-first-name"}},[_vm._v(" Entity Name ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.entity_name),expression:"form.entity_name"}],staticClass:"\n              appearance-none\n              block\n              w-full\n              bg-gray-200\n              text-gray-700\n              border\n              rounded\n              py-3\n              px-4\n              mb-3\n              leading-tight\n              focus:outline-none focus:bg-white\n            ",class:{
              'is-invalid': _vm.form.errors.has('entity_name'),
            },attrs:{"id":"grid-first-name","type":"text","placeholder":"Enter agent entity name"},domProps:{"value":(_vm.form.entity_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "entity_name", $event.target.value)}}})]),_c('div',{staticClass:"w-full md:w-1/2 px-3"},[_c('label',{staticClass:"\n              block\n              uppercase\n              tracking-wide\n              text-gray-700 text-xs\n              font-bold\n              mb-2\n            ",attrs:{"for":"grid-last-name"}},[_vm._v(" Nature of business ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nature_of_business),expression:"form.nature_of_business"}],staticClass:"\n              appearance-none\n              block\n              w-full\n              bg-gray-200\n              text-gray-700\n              border border-gray-200\n              rounded\n              py-3\n              px-4\n              leading-tight\n              focus:outline-none focus:bg-white focus:border-gray-500\n            ",class:{
              'is-invalid': _vm.form.errors.has('nature_of_business'),
            },attrs:{"id":"grid-last-name","type":"text","placeholder":""},domProps:{"value":(_vm.form.nature_of_business)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "nature_of_business", $event.target.value)}}})])]),_c('div',{staticClass:"flex flex-wrap -mx-3 mb-6"},[_c('div',{staticClass:"w-full px-3 mb-6 md:mb-0"},[_c('label',{staticClass:"\n              block\n              uppercase\n              tracking-wide\n              text-gray-700 text-xs\n              font-bold\n              mb-2\n            ",attrs:{"for":"grid-zip"}},[_vm._v(" Business Address ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.business_address),expression:"form.business_address"}],staticClass:"\n              appearance-none\n              block\n              w-full\n              bg-gray-200\n              text-gray-700\n              border border-gray-200\n              rounded\n              py-3\n              px-4\n              leading-tight\n              focus:outline-none focus:bg-white focus:border-gray-500\n            ",class:{
              'is-invalid': _vm.form.errors.has('business_address'),
            },attrs:{"id":"grid-zip","type":"text","placeholder":""},domProps:{"value":(_vm.form.business_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "business_address", $event.target.value)}}})])]),_c('div',{staticClass:"flex flex-wrap -mx-3 mb-2"},[_c('div',{staticClass:"w-full md:w-1/3 px-3 mb-6 md:mb-0"},[_c('label',{staticClass:"\n              block\n              uppercase\n              tracking-wide\n              text-gray-700 text-xs\n              font-bold\n              mb-2\n            ",attrs:{"for":"grid-city"}},[_vm._v(" Contact Person ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.contact_person),expression:"form.contact_person"}],staticClass:"\n              appearance-none\n              block\n              w-full\n              bg-gray-200\n              text-gray-700\n              border border-gray-200\n              rounded\n              py-3\n              px-4\n              leading-tight\n              focus:outline-none focus:bg-white focus:border-gray-500\n            ",class:{
              'is-invalid': _vm.form.errors.has('contact_person'),
            },attrs:{"id":"grid-city","type":"text","placeholder":""},domProps:{"value":(_vm.form.contact_person)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "contact_person", $event.target.value)}}})]),_c('div',{staticClass:"w-full md:w-1/3 px-3 mb-6 md:mb-0"},[_c('label',{staticClass:"\n              block\n              uppercase\n              tracking-wide\n              text-gray-700 text-xs\n              font-bold\n              mb-2\n            ",attrs:{"for":"grid-zip"}},[_vm._v(" Contact Person Mobile ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.contact_person_number),expression:"form.contact_person_number"}],staticClass:"\n              appearance-none\n              block\n              w-full\n              bg-gray-200\n              text-gray-700\n              border border-gray-200\n              rounded\n              py-3\n              px-4\n              leading-tight\n              focus:outline-none focus:bg-white focus:border-gray-500\n            ",class:{
              'is-invalid': _vm.form.errors.has('contact_person_number'),
            },attrs:{"id":"grid-zip","type":"text","placeholder":""},domProps:{"value":(_vm.form.contact_person_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "contact_person_number", $event.target.value)}}})])]),_vm._m(1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-between items-center mb-6"},[_c('div',[_c('h1',{staticClass:"text-4xl"},[_vm._v("Create a new agent")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-wrap -mx-3 px-3 mt-8"},[_c('button',{staticClass:"\n            px-4\n            py-2\n            rounded\n            text-white\n            inline-block\n            shadow-lg\n            bg-blue-500\n            hover:bg-blue-600\n            focus:bg-blue-700\n          ",attrs:{"type":"submit"}},[_vm._v(" Save ")])])}]

export { render, staticRenderFns }