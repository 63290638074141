<template>
  <div class="">
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">Create a new agent</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>
    </div>

    <div class="bg-white w-full p-6 rounded-lg shadow">
      <form class="w-full" @submit.prevent="onSubmit">
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="grid-first-name"
            >
              Entity Name
            </label>
            <input
              v-model="form.entity_name"
              :class="{
                'is-invalid': form.errors.has('entity_name'),
              }"
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="grid-first-name"
              type="text"
              placeholder="Enter agent entity name"
            />
            <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="grid-last-name"
            >
              Nature of business
            </label>
            <input
              v-model="form.nature_of_business"
              :class="{
                'is-invalid': form.errors.has('nature_of_business'),
              }"
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-gray-200
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none focus:bg-white focus:border-gray-500
              "
              id="grid-last-name"
              type="text"
              placeholder=""
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="grid-zip"
            >
              Business Address
            </label>
            <input
              v-model="form.business_address"
              :class="{
                'is-invalid': form.errors.has('business_address'),
              }"
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-gray-200
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none focus:bg-white focus:border-gray-500
              "
              id="grid-zip"
              type="text"
              placeholder=""
            />
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-2">
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="grid-city"
            >
              Contact Person
            </label>
            <input
              v-model="form.contact_person"
              :class="{
                'is-invalid': form.errors.has('contact_person'),
              }"
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-gray-200
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none focus:bg-white focus:border-gray-500
              "
              id="grid-city"
              type="text"
              placeholder=""
            />
          </div>
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="grid-zip"
            >
              Contact Person Mobile
            </label>
            <input
              v-model="form.contact_person_number"
              :class="{
                'is-invalid': form.errors.has('contact_person_number'),
              }"
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-gray-200
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none focus:bg-white focus:border-gray-500
              "
              id="grid-zip"
              type="text"
              placeholder=""
            />
          </div>
          <!-- <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-zip"
            >
              Contact Person Email
            </label>
            <input
              v-model="form.contact_person_email"
              :class="{
                'is-invalid': form.errors.has('contact_person_email'),
              }"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-zip"
              type="text"
              placeholder=""
            />
          </div> -->
        </div>

        <div class="flex flex-wrap -mx-3 px-3 mt-8">
          <button
            class="
              px-4
              py-2
              rounded
              text-white
              inline-block
              shadow-lg
              bg-blue-500
              hover:bg-blue-600
              focus:bg-blue-700
            "
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Form from "vform";
// import BranchSales from "../../components/Charts/BranchSales";

import { mapGetters } from "vuex";
import checkPermissionExist from "../../mixins/checkPermissionExist";

export default {
  middleware: "auth",
  layout: "default",

  components: {
    // BranchSales,
    // HelloWorld
  },

  mixins: [checkPermissionExist],
  computed: mapGetters({
    user: "auth/user",
  }),

  created() {
    if (!this.checkPermissionExist(this.user.data, "agents-create")) {
      // console.log("Hereeeeeeeeeeeeeeeeeee");
      this.$router.go(-1);
      return false;
    }
  },
  data: () => ({
    isLoading: false,
    form: new Form({
      entity_name: "",
      nature_of_business: "",
      business_address: "",
      contact_person: "",
      contact_person_number: "",
      contact_person_email: "",
    }),
  }),

  methods: {
    async onSubmit() {
      // Submit the form
      this.isLoading = true;

      try {
        await this.form.post("/agent/store");

        this.isLoading = false;
        this.$router.push({ path: "/agents" });

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },
  },
};
</script>
